<template>
  <b-row>
    <b-col>
      <b-card>
        <b-form @submit.prevent>
          <b-row>
            <b-col cols="12" :md="applicationView ? 4 : 6">
              <b-form-group label="Фамилия" label-for="last_name">
                <b-form-input
                  id="last_name"
                  v-model="user.last_name"
                  placeholder="Фамилия"
                />
              </b-form-group>

              <b-form-group label="Имя" label-for="first_name">
                <b-form-input
                  id="first_name"
                  v-model="user.first_name"
                  placeholder="Имя"
                />
              </b-form-group>

              <b-form-group label="Отчество" label-for="middle_name">
                <b-form-input
                  id="middle_name"
                  v-model="user.middle_name"
                  placeholder="Отчество"
                />
              </b-form-group>

              <b-form-group label="Пол" label-for="gender">
                <b-form-select
                  id="gender"
                  v-model="user.profile.gender"
                  :options="[
                    { value: 'M', text: 'М' },
                    { value: 'F', text: 'Ж' },
                  ]"
                  placeholder="МЖ"
                />
              </b-form-group>

              <b-form-group label="Дата Рождения" label-for="birth_date">
                <b-form-input
                  id="birth_date"
                  v-model="user.profile.birth_date"
                  placeholder="01.01.1980"
                  type="text"
                />
              </b-form-group>

              <b-form-group label="Номер телефона" label-for="username">
                <validation-provider
                  #default="{ errors }"
                  name="Номер телефона"
                  rules="phone"
                >
                  <b-form-input
                    id="username"
                    v-model="user.username"
                    placeholder="+375000000000"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Email" label-for="email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="email"
                >
                  <b-form-input
                    id="email"
                    v-model="user.email"
                    placeholder="Email"
                    type="email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                label="Идентификационный номер"
                label-for="id_number"
              >
                <b-form-input
                  id="id_number"
                  v-model="user.profile.id_number"
                  placeholder="xxxxxxxxxxxxxx"
                />
              </b-form-group>

              <b-row v-if="applicationView">
                <b-col md="6">
                  <b-form-group label="Тип документа">
                    <b-form-select
                      v-model="doc.document_type"
                      :options="docTypes"
                      text-field="display_name"
                      value-field="value"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Номер паспорта">
                    <b-form-input v-model="doc.document_number" />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Дата выдачи">
                    <b-form-input v-model="doc.issue_date" type="text" />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Срок действия">
                    <b-form-input v-model="doc.valid_till" type="text" />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group label="Кем выдан">
                    <b-form-textarea v-model="doc.issued_by" />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group label="Адрес регистрации">
                    <b-form-textarea v-model="doc.assigned_address" />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group label="Адрес жительства">
                    <b-form-textarea v-model="doc.living_address" />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group label="Место работы">
                    <b-form-input v-model="user.profile.job_place" />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-checkbox v-model="doc.is_identified" inline switch
                    >Идентифицирован?
                  </b-form-checkbox>
                </b-col>
              </b-row>
              <b-col class="mt-4" md="12">
                <b-button
                  class="mr-1"
                  type="submit"
                  variant="primary"
                  @click="updateUser"
                >
                  Обновить
                </b-button>
              </b-col>
            </b-col>
            <b-col v-if="applicationView" md="8">
              <div class="d-flex justify-content-end mb-2">
                <b-form-file
                  id="docFileUpload"
                  v-model="docPhoto"
                  class="d-none"
                  plain
                  @input="uploadDoc"
                />
                <b-button variant="primary" @click="showUpload">
                  Загрузить
                </b-button>
              </div>
              <swiper
                ref="swiperDocs"
                :auto-destroy="true"
                :auto-update="true"
                :options="swiperOptions"
                class="text-center swiper-gallery gallery-top"
                instance-name="swiperDocs"
              >
                <swiper-slide
                  v-for="(photo, index) in doc.doc_photos"
                  :key="photo.id"
                >
                  <b-button
                    size="24"
                    variant="primary"
                    class="position-absolute zindex-1"
                    :style="{ left: '10px' }"
                    @click="rotatePhoto(index, 90)"
                  >
                    <feather-icon icon="RotateCwIcon" size="24" />
                  </b-button>
                  <b-img
                    :src="photo.photo"
                    :style="getImageStyles(photo.rotation)"
                    fluid
                    width="500"
                  />
                  <div
                    :class="{
                      'swiper-button-disabled':
                        index === doc.doc_photos.length - 1,
                    }"
                    class="swiper-button-next"
                    @click="nextSlide"
                  ></div>
                  <div
                    :class="{ 'swiper-button-disabled': index === 0 }"
                    class="swiper-button-prev"
                    @click="prevSlide"
                  ></div>
                </swiper-slide>
                <div slot="pagination" class="swiper-pagination1"></div>
              </swiper>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import { ValidationProvider } from 'vee-validate'
import { email, phone } from '@validations'

export default {
  name: 'ApplicationClientInfoCard',
  components: {
    Swiper,
    SwiperSlide,
    ValidationProvider,
  },
  props: ['id', 'redirect', 'applicationView'],
  data() {
    return {
      user: {
        profile: {},
      },
      doc: {},
      docPhoto: null,
      docPhotos: [],
      docTypes: [],
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination1',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      email,
      phone,
    }
  },
  watch: {
    id() {
      this.getUser()
      this.getLatestDocs()
    },
  },
  created() {
    if (this.id) {
      this.getUser()
    }
    if (this.applicationView) {
      this.getDocOptions()
      if (this.id) {
        this.getLatestDocs()
      }
    }
  },
  methods: {
    async getUser() {
      const res = await this.$http.get(`admin/users/${this.id}/`)
      this.user = res.data
    },
    async getDocOptions() {
      const res = await this.$http.options('admin/docs/')
      this.docTypes = res.data.actions.POST.document_type.choices
    },
    async getLatestDocs() {
      const res = await this.$http.get(`admin/doc-latest/?user=${this.id}`)
      this.doc = {
        ...res.data,
        doc_photos: res.data.doc_photos.map((photo) => ({
          ...photo,
          rotation: 0,
        })),
      }
    },
    async updateUser() {
      try {
        const res = await this.$http.patch(`admin/users/${this.id}/`, this.user)
        this.user = res.data
        if (this.applicationView) {
          const resDoc = await this.$http.patch(
            `admin/docs/${this.doc.id}/`,
            this.doc
          )
          this.doc = resDoc.data
        }
        this.$toast.success('Обновлено', { position: 'bottom-left' })
        if (this.redirect) {
          this.redirect()
        }
      } catch (e) {
        this.$toast.error('Ошибка', {})
      }
    },
    showUpload() {
      document.getElementById('docFileUpload').click()
    },
    async uploadDoc() {
      try {
        const formData = new FormData()
        formData.append('photo_type', 4)
        formData.append('document', this.doc.id)
        formData.append('photo', this.docPhoto)
        await this.$http({
          method: 'POST',
          url: 'docs/photo/',
          data: formData,
          config: { headers: { 'content-type': 'multipart/form-data' } },
        })
        await this.getLatestDocs()
        this.$toast.success('Загружено')
      } catch (e) {
        this.$toast.error('Ошибка')
      }
    },
    nextSlide() {
      this.$refs.swiperDocs.$swiper.slideNext()
    },
    prevSlide() {
      this.$refs.swiperDocs.$swiper.slidePrev()
    },
    rotatePhoto(index, degrees) {
      this.doc.doc_photos[index].rotation += degrees
    },
    getImageStyles(rotation) {
      const width = rotation % 180 === 0 || rotation === 0 ? '500px' : 'auto'
      const height = rotation % 180 === 0 || rotation === 0 ? 'auto' : '500px'

      return {
        transform: `rotate(${rotation}deg)`,
        width,
        height,
      }
    },
  },
}
</script>
